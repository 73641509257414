
<template>
  <div>
    <div class="operationControl">
      <div class="searchbox">
        <div title="课件名称" class="searchboxItem ci-full">
          <span class="itemLabel">课件名称:</span>
          <el-input v-model="searchName" clearable type="text" size="small" placeholder="请输入课件名称" />
        </div>
        <!-- <div title="培训类型" class="searchboxItem ci-full flexcc">
            <span class="itemLabel">培训类型:</span>
            <tree
              ref="tree"
              @eventBtn="childBack"
              :ruleForm="ruleForm"
              size="small"
              @clearParams="clearParams"
              modal
              typeStu
            />
          </div>
          <div title="资源提供者" class="searchboxItem ci-full">
            <span class="itemLabel">资源提供者:</span>
            <el-select
              size="small"
              v-model="compId"
              :remote-method="getCompanyList"
              remote
              filterable
              clearable
              placeholder="请至少输入两个字搜索"
            >
              <el-option
                v-for="item in CompanyList"
                :key="item.compId"
                :label="item.compName"
                :value="item.compId"
              ></el-option>
            </el-select>
          </div> -->
        <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
        </div>
      </div>
      <div class="df searchbox">
        <!-- <el-button type="primary" class="bgc-bv" round @click="doRoute">我要发布</el-button> 屏蔽我要发布按钮 -->
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
          style="width: 100%" stripe :header-cell-style="tableHeader">
          <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
          <el-table-column width="240" label="课件名称" align="left" show-overflow-tooltip prop="kpointName" fixed />
          <el-table-column label="培训类型" align="left" show-overflow-tooltip prop="trainTypeNamePath" width="120" />
          <el-table-column label="岗位类型" show-overflow-tooltip align="left" width="160">
            <template slot-scope="scope">{{
              scope.row.postName || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="行业类型" align="left" show-overflow-tooltip width="160">
            <template slot-scope="scope">{{
              scope.row.industryNamePath || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="职业/工种" show-overflow-tooltip align="left" width="220">
            <template slot-scope="scope">{{
              scope.row.occupationNamePath || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="培训等级" show-overflow-tooltip align="left">
            <template slot-scope="scope">{{
              scope.row.trainLevelName || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="时长" show-overflow-tooltip align="right">
            <span slot-scope="scope">{{
              getTime(scope.row.kpointDuration)
            }}</span>
          </el-table-column>
          <el-table-column label="课件学时" prop="kpointLessonNum" show-overflow-tooltip align="right" />
          <el-table-column label="操作" align="center" width="100px" fixed="right">
            <div slot-scope="scope">
              <!-- <el-button style="padding: 0 5px" type="text" size="mini" @click="showVideo(scope.row)">预览</el-button> -->
              <el-button style="padding: 0 5px" :disabled="scope.row.isPull" type="text" size="mini" @click="salesState(scope.row.kpointId)">拉取课件</el-button>
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog v-if="dialogVisible1" :title="videotitle" :visible.sync="dialogVisible1" width="60%"
      :before-close="handleClose">
      <player_2 :videoId="videoId" :videoTime="videoTime" :kpointSource="kpointSource" v-if="videoType" />
      <player_1 :watermark="true" :videoId="videoId" :videoTime="videoTime" :kpointSource="kpointSource" v-else />
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import player_1 from "@/components/player";
import player_2 from "@/components/playerTrysee";
import { createSwigger, distorySwigger } from "../../../utils/swigger";
export default {
  name: "TestPaper",
  components: {
    Empty,
    PageNum,
    tree,
    player_1,
    player_2,
  },
  mixins: [List],
  data() {
    return {
      CompanyList: [], // 资源提供者
      activeName: "first",
      searchName: "",
      trainTypeId: "", // 培训类型
      compId: "", // 资源提供者
      props: {
        // 联级配置
        value: "id",
        label: "label",
        emitPath: false,
      },
      params: {}, // tree 参数
      ruleForm: {
        Trainingtype: "",
      },
      videoId: "", // 視頻id
      videoType: true, // true-可以看；false-试看
      dialogVisible1: false,
      videotitle: "",
      videoTime: "",
      status: 1,
    };
  },
  created() { },
  watch: {
    compId: function (val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
  computed: {},
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        KEYWORDS: this.searchName || "",
        //   compId: this.compId || "",
      };
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/biz/qa/QaCourse/pullQaVideoListPage",
        params,
        pageNum,
      });
    },
    getTime(val) {
      let m = parseInt(val / 60);
      let s = val % 60;
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return m + ":" + s;
    },
    handleClose() {
      (this.dialogVisible1 = false), (this.status = 3);
      distorySwigger();
    },
    // 预览
    showVideo(row) {
      let _this = this;
      _this
        .$post("/biz/qa/QaCourse/pullQaPlayInfo", {
          videoId: row.kpointVideoId,
        })
        .then((result) => {
          console.log(result)
          _this.videoId = row.kpointId;
          _this.videotitle = row.kpointName;
          _this.kpointSource = '30';
          _this.$nextTick(()=> {
            _this.dialogVisible1 = true;
          })
        });
    },
    // 拉取课件
    salesState(kpointId) {
      this.$post("/biz/qa/QaCourse/pullQaKpoint", {
        videoId: kpointId,
      })
        .then((res) => {
          if (res.status == 0) {
            this.$message({
              type: "success",
              message: "该课件已加入我的课件",
            });
            this.getData(-1);
          }
        })
        .catch(() => {
          return;
        });
    },
    // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 我要发布
    doRoute() {
      this.$router.push({
        path: "/web/myresourseList",
        query: {
          active: "second",
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped></style>